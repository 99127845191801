<template>
  <div class="upload-proof">
    <v-app>
      <v-container fluid>
        <v-card class="pt-8 mt-6 mb-12">
          <v-app-bar class="navbar" app height="50px">
            <div class="header-title">
              <div class="d-flex align-center">
                <v-btn
                  icon
                  small
                  dark
                  class="mr-2 btn-prev"
                  @click.prevent="$router.back()"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <h1>อัปโหลดรูปสินค้า</h1>
              </div>
            </div>
          </v-app-bar>

          <v-container fluid>
            <div class="wrapper-upload-proof">
              <div>
                <h4>
                  หมายเลขคำสั่งซื้อ :
                  {{
                    this.$store.getters.getOrderDetails.purchaseRequisitionNo
                  }}
                </h4>
                <h4>
                  สถานะการสั่งซื้อ :
                  <span style="color: #0288d1" class="status-order-list">{{
                    this.$store.getters.getOrderDetails
                      .purchaseRequisitionStatus
                  }}</span>
                </h4>
                <h5 class="font-weight-regular">
                  วันที่สั่งซื้อ :
                  {{
                    this.$store.getters.getOrderDetails.createDate | dateFormat
                  }}
                </h5>
              </div>
              <v-divider class="mb-2 mt-2"></v-divider>
              <div class="detail-school">
                <h4>ผู้สั่งซื้อ</h4>
                <p class="mb-1">
                  โรงเรียน :
                  {{ this.$store.getters.getOrderDetails.school.name }}
                </p>
                <p class="mb-1">
                  ที่อยู่ :
                  {{ this.$store.getters.getOrderDetails.school.address }}
                  {{ this.$store.getters.getOrderDetails.school.province }}
                  {{ this.$store.getters.getOrderDetails.school.postcode }}
                </p>
                <p class="mb-1">
                  ติดต่อ :
                  {{ this.$store.getters.getOrderDetails.school.shippingPhone }}
                </p>
              </div>
              <v-divider class="mb-2 mt-2"></v-divider>

              <div class="upload">
                  <h4>อัปโหลดหลักฐาน</h4>
                <input
                  type="file"
                  multiple
                  accept="image/jpeg/png"
                  @change="onFileChange"
                  capture="camera"
                    class="my-2"
                />

                <div class="my-2" v-for="(image, key) in images" :key="key">
                  <div class="d-flex">
                    <img width="100px" height="100px" class="preview" :ref="'image'" />
                    {{ image.name }}
                  </div>
                </div>
                <div class="text-center">
                  <v-btn color="primary">บันทึก</v-btn>
                </div>
              </div>
            </div>
          </v-container>
        </v-card>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "UploadProof",
  data() {
    return {
      images: [],
    };
  },
  filters: {
    dateFormat: function (date) {
      return moment(date).format("DD MMMM YYYY, h:mm:ss a.");
    },
  },
  methods: {
    onFileChange(e) {
      let vm = this;
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        console.log(selectedFiles[i]);
        this.images.push(selectedFiles[i]);
      }

      for (let i = 0; i < this.images.length; i++) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.$refs.image[i].src = reader.result;

          console.log(this.$refs.image[i].src);
        };

        reader.readAsDataURL(this.images[i]);
      }

      console.log(this.images);
    },
  },
};
</script>

<style scoped>
.navbar {
  position: absolute !important;
  width: 95%;
  top: -24px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background: #2e2253 !important;
  border-radius: 3px !important;
}
.header-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-title h1 {
  color: #fff;
  font-weight: 400;
  font-size: 19px;
}

#preview {
  display: flex;
  align-items: center;
}

#preview img {
  width: 100px;
  height: 100px;
}
</style>